import React from 'react'
import './skills.css'
import UIDesign from '../../assets/images/ui-design.png'
import WebDesign from '../../assets/images/website-design.png'
import AppDesign from '../../assets/images/app-design.png'

const Skills = () => {
    return (
        <section id='skills'>
            <span className="skillsTitle">What I do </span>
            <span className="skillsDesc">I'm a seasoned software engineer with years of experience designing and building full-stack web applications. I have a passion for helping businesses connect with consumers through an intuitive digital experience. </span>
            <div className="skillsBars">

                <div className='skillsBar'>
                    <img src={UIDesign} alt="UIDesign" className="skillsBarImg" />
                    <div className="skillsBarText">
                        <h2>UI/UX Design </h2>
                        {/* I understand the importance of a seamless yet meaningful user experience. Reaching end-user needs in a visually intuitive way is always my goal. */}
                        <p> </p>
                    </div>
                </div>

                <div className='skillsBar'>
                    <img src={WebDesign} alt="WebDesign" className="skillsBarImg"/>
                    <div className="skillsBarText">
                        <h2>Website Design </h2>
                        {/* I design and build web interfaces from scratch so that I can meet all individualized needs of a client.  */}
                        <p> </p>
                    </div>
                </div>

                <div className='skillsBar'>
                    <img src={AppDesign} alt="AppDesign" className="skillsBarImg"/>
                    <div className="skillsBarText">
                        <h2>App Design </h2>
                        <p> </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Skills;