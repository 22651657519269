import React from 'react';
import './intro.css'
//import bg from '../../assets/images/headshotNEW.PNG'
import btnImg from '../../assets/images/hireme.png'
import {Link} from 'react-scroll'

const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <span className="hey">Hey, </span>
                <span className="introText">I'm <span className="introName">Lisa Mulhearn </span> <br />Software Engineer. Full-Stack Developer. </span>
                <p className="introPara">I'm a skilled web developer with experience creating visually appealing and user-friendly websites. </p>
                <Link>
                    <button className="btn" onClick={() => {
                        document.getElementById('contactPage').scrollIntoView({behavior: 'smooth'})
                    }}>
                        <img className="btnImg" src={btnImg} alt="Hire Me" />
                        Hire Me
                    </button>
                </Link>
            </div>
            { /* <img src={bg} alt="profile" className="bg" /> */}
        </section>
    )
}

export default Intro;