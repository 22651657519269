import React from 'react'
import './footer.css'

const Footer = () => {
    return(
        <footer className='footer'>
            Copyright &#169; 2024 Lisa Mulhearn. All rights reserved.
        </footer>
    );
}

export default Footer;