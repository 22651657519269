import React, {useState} from "react";
import './navbar.css'
import logo from '../../assets/images/logo-L.png'
import contactImg from '../../assets/images/contact.png'
import {Link} from 'react-scroll';
import menu from '../../assets/images/menu2.png'

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <nav className="navbar">
            <img src={logo} alt="logo" className="logo"/>
            <div className="desktopMenu">
                <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Home</Link>
                <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">About</Link>
                {/* <Link activeClass='active' to='models' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Models</Link> */}
                {/*  <Link className="desktopMenuListItem">Clients</Link> */}
            </div>
            <button className="desktopMenuBtn"
                    onClick={() => {
                        document.getElementById('contactPage').scrollIntoView({behavior: 'smooth'})
                    }}>
                <img src={contactImg} alt="" className="desktopMenuImg"/>
                Contact Me
            </button>

            <img src={menu} alt="Menu" className="mobMenu" onClick={() => setShowMenu(!showMenu)}/>
            <div className="navMenu" style={{display: showMenu? 'flex' : 'none'}}>
                <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Home</Link>
                <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={() => setShowMenu(false)}>About</Link>
                {/*  <Link activeClass='active' to='models' spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Models</Link> */}
                <Link activeClass='active' to='contactPage' spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Contact</Link>
            </div>

        </nav>
    )
}

export default Navbar;