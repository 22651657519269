import React, {useRef} from 'react'
import emailjs from '@emailjs/browser'
import './contact.css'
import FacebookIcon from '../../assets/images/facebook-icon.png'
//import TwitterIcon from '../../assets/images/twitter.png'
//import YoutubeIcon from '../../assets/images/youtube.png'
import InstagramIcon from '../../assets/images/instagram.png'
import XIcon from '../../assets/images/XIcon.png'


const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        if(!document.getElementById("name").value){
            console.log('name empty');
            alert('Enter your name')
            return
        }
        if(!document.getElementById("email").value){
            console.log('email empty');
            alert('Enter your email')
            return
        }
        if(!document.getElementById("msg").value){
            console.log('message empty');
            alert('Enter your message')
            return
        }

        emailjs.sendForm('service_2unled3', 'template_q2v8jdh', form.current, {publicKey: 'iZ7u7qhyoNBL-OmkA',}).then(() => {
            console.log('SUCCESS!');
            e.target.reset();
            alert('Email sent!');
        }, (error) => {
            console.log('FAILED...', error.text);
        },);
    }

    return(
        <section id='contactPage'>
            <div id='contact'>
                <h1 className='contactPageTitle'>Contact Me </h1>
                <span className='contactDesc'>Please fill out the form below to discuss any work opportunities </span>
                <form className='contactForm' ref={form} onSubmit={sendEmail}>
                    <input type='text' className='name' id='name' placeholder='Your Name' name='from_name'/>
                    <input type='email' className='email' id='email' placeholder='Your Email' name='reply_to'/>
                    <textarea className='msg' id='msg' rows='5' placeholder='Your Message' name='message'></textarea>
                    <button className='submitBtn' type='submit' value='Send'>Submit</button>
                    <div className='links'>
                        <img src={FacebookIcon} alt="Facebook" className='link'/>
                        <img src={XIcon} alt="Twitter" className='link'/>
                        {/*    <img src={YoutubeIcon} alt="Youtube" className='link'/> */}
                        <img src={InstagramIcon} alt="Instagran" className='link'/>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact;